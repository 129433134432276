import { PortableText } from '@portabletext/react';
import Grid from '@mui/material/Grid';
import portableTextComponents from 'components/widgets/portableComponents';
import styled from '@emotion/styled';
import theme from 'theme';

const Base = styled.div(({
  theme,
}) => `
  width: 100%;
  height: ${theme.spacing(5)};
  background-color: ${theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.938rem;
  line-height: 2.5rem;
  padding: ${theme.spacing(1, 3)};
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  &:hover {
    background-color: #C61C1C;
  }
`);

/* TODO: clean up the styling of link */
const Text = styled.div`
  ${theme.breakpoints.down('sm')}{
    font-size: 0.8rem;
  }
  color: #fff;
  text-align: center;
  justify-content: center;
  font-size: 0.938rem;
  /* Link style */
  & > p > a {
    color: #fff;
    font-size: 0.938rem;
    font-weight: 500;
    text-decoration: underline;
    letter-spacing: 0.5px;
  }
`;

const InfoBar = ({
  infobar,
}) => {
  return <Base>
    <Grid container>
      <Grid item xs={12}>
        <Text>
          <PortableText
            value={infobar?.content}
            components={portableTextComponents}
          />
        </Text>
      </Grid>
    </Grid>
  </Base>;
};

export default InfoBar;
